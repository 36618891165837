/* global
 autosize
 bsCustomFileInput
 gettext
 initHtmlTextarea
 removeText
 $x
 */


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($data.html) {
        $("#load-circulation-change-log").html($data.html);
      }

      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// #############################################################################
// REDIRECT OVERRIDE

function checkRedirect ($data) {
  if ($data.toaster) {
    $("body").toaster("saveToaster", $data.toaster);
  }
  if (window.location.href.includes($data.redirect)) {
    window.location.reload();
  } else {
    window.location.href = $data.redirect;
  }
}

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// DATA TABLE

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      const $input = $table.querySelector("[data-multiple-select-item]");

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip();

        if ($input) {
          $input.$multipleSelectItem.reset();
        }
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      return html;
    },
  });
});

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item);
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();

  if ($(".django-select2").length) {
    const $djangoSelect2 = $(".django-select2");

    if ($djangoSelect2.length) {
      // Init Select2 Fields in the modal dialog, only if there is any django-select2 field.
      $djangoSelect2.djangoSelect2({ dropdownParent: $("#modal") });
    }
  }

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
        // blp_aca delete checkpoint, photo, file or circulation remove html element
        if ($data.type && $data.id) {
          if ($data.type === "checkpoint" || $data.type === "photo" || $data.type === "file") {
            $("#" + $data.type + "-" + $data.id.toString()).addClass("d-none").removeClass("d-flex");
          } else if ($data.type === "circulation") {
            $("#" + $data.id.toString()).addClass("d-none");
            $("[data-show-circulation=\"" + $data.id.toString() + "\"]").addClass("d-none");
          }
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  $x.modal.open(this.href, {
    onModalOpen: $x.onModalOpenDefault,
  });
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

// #############################################################################
// BLP ACA

function getCookie (name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = jQuery.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function set_aca_project (pn) {
  const url = $("#set-project-url").data("url");
  $.ajax({
    url: url,
    method: "POST",
    data: {
      pn: pn,
      csrfmiddlewaretoken: getCookie("csrftoken"),
    },
    dataType: "json",
    success: function (data) {
      if (data.submit === "success") {
        $("#selected-aca-project").html(data["selected-project"]).removeClass("text-danger");
        sessionStorage.setItem("pn", pn);
        if (data.redirect) {
          checkRedirect(data);
        }
      } else if (data.submit === "fail") {
        $("#selected-aca-project").html(data["error-msg"]).addClass("text-danger");
        sessionStorage.removeItem("pn");
      }
    },
  });
}

function get_aca_project () {
  const url = $("#get-project-url").data("url");
  $.ajax({
    url: url,
    method: "GET",
    success: function (data) {
      if (data.submit === "success") {
        $("#selected-aca-project").html(data["selected-project"]);
      } else if (data.submit === "fail") {
        $("#selected-aca-project").html(data["error-msg"]);
      }
    },
  });
}

function search (url) {
  const search_value = $("#search-in-circulations").val();
  $.ajax({
    url: url,
    method: "POST",
    data: {
      search_value: search_value,
      csrfmiddlewaretoken: getCookie("csrftoken"),
    },
    dataType: "json",
    success: function (data) {
      if (data.submit === "success") {
        const list = data.ids;
        $("[data-circulation-visibility]").addClass("d-none");
        for (let i = 0; i < list.length; i++) {
          $(`#${list[i]}`).removeClass("d-none");
        }
      }
    },
  });
}

function get_all_marked () {
  const all_circulation = $("[data-circulation-report-marked]");
  const marked_circulation_list = [];
  for (const circulation of all_circulation) {
    if (circulation.checked) {
      marked_circulation_list.push($(circulation).data("circulation-report-marked"));
    }
  }
  const output = {};
  for (let idx = 0; idx < marked_circulation_list.length; idx++) {
    const all_checkpoints = $(`#${marked_circulation_list[idx]}`).find(".report-mark");
    output[marked_circulation_list[idx]] = [];
    for (const checkpoint of all_checkpoints) {
      const id = checkpoint.id.replace("checkpoint-", "");
      output[marked_circulation_list[idx]].push(id);
    }
  }
  return output;
}

// #############################################################################
// SELECT - PROJECT

$body.on("click", "#projects_table>tbody>tr", function (event) {
  const pn = $(event.target).closest("tr").find(".project-number").html();
  $(".icon-inactive-project").removeClass("d-none");
  $(".icon-active-project").addClass("d-none");
  $(event.target).closest("tr").find(".icon-active-project").removeClass("d-none");
  set_aca_project(pn);
});

$body.on("click", "#projects_mobile_table>tbody>tr", function (event) {
  const pn = $(event.target).closest("tr").find(".project-number").html();
  set_aca_project(pn);
});


// #############################################################################
// LOAD - CIRCULATION

function clear_active_mark () {
  $("[data-show-circulation]").removeClass("shown_circulation");
}

$body.on("click", "#load-circulation", function () {
  $("#load-circulation-project_number").val(sessionStorage.getItem("pn"));
  $("#load-circulation-form").submit();
});

$(function () {
  get_aca_project();
  const path = (window.location.pathname).split("/");
  const id = (path.length > 1) ? (path[path.length - 1]) : (null);
  if (id) {
    $("[data-show-circulation=\"" + id + "\"]").addClass("shown_circulation");
  }
});

$body.on("click", "#open-all-circulations", function () {
  $("[data-circulations-details]").attr("open", true);
});

$body.on("click", "#close-all-circulations", function () {
  $("[data-circulations-details]").attr("open", false);
});

$body.on("click", "#show-all-circulations", function (e) {
  $("[data-circulation-visibility]").removeClass("d-none");
});

$body.on("click", "[data-show-circulation]", function (e) {
  $("[data-circulation-visibility]").addClass("d-none");
  const id = $(e.target).closest("button").data("show-circulation");
  $(`#${id}`).removeClass("d-none");
  clear_active_mark();
  $(this).addClass("shown_circulation");
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

$body.on("click", "[data-search-url]", function (e) {
  const url = $("[data-search-url]").data("search-url");
  search(url);
});

$body.on("keyup", "#search-in-circulations", (event) => {
  const key = event.key;
  if (key === "Enter") {
    event.preventDefault();
    const url = $("[data-search-url]").data("search-url");
    search(url);
    clear_active_mark();
  }
});

$body.on("click", "#only-outstanding", function (event) {
  const btn = $("#only-outstanding");
  const toggle_text = btn.data("toggle-text");
  const text = btn.html();

  btn.data("toggle-text", text);
  btn.html(toggle_text);
  $("[data-done]").toggleClass("d-none");
});

// #############################################################################
// EDIT - CIRCULATION

$body.on("click", "#id_circulationform-unique_check", toggle_CR);

function toggle_CR (event) {
  const unique_check = $(event.target);
  $("#id_circulationform-control_rhythm").prop("disabled", unique_check.prop("checked"));
}

toggle_CR({ target: "#id_circulationform-unique_check" });

// #############################################################################
// REPORT - CIRCULATION

$body.on("click", "#mark-all-for-report", function () {
  $("[data-circulation-report-marked]").prop("checked", this.checked);
  $("[data-mark-all]").prop("checked", this.checked);
  $("[data-mark-checkpoint]").prop("checked", this.checked);

  if (this.checked) {
    $(".checkpoint-header").addClass("report-mark");
    $("[data-show-circulation]").addClass("report-mark");
    $("[data-mark-all]").prop("disabled", false);
    $("[data-mark-checkpoint]").prop("disabled", false);
    $("#create-report").prop("disabled", false);
  } else {
    $(".checkpoint-header").removeClass("report-mark");
    $("[data-show-circulation]").removeClass("report-mark");
    $("[data-mark-all]").prop("disabled", true);
    $("[data-mark-checkpoint]").prop("disabled", true);
    $("#create-report").prop("disabled", true);
  }
});

$body.on("click", "[data-circulation-report-marked]", function (e) {
  const is_checked = $(e.target).is(":checked");
  const circulation_id = $(e.target).data("circulation-report-marked");
  if (is_checked) {
    $(`[data-show-circulation="${circulation_id}"]`).addClass("report-mark");
    $(`[data-mark-checkpoint="${circulation_id}"]`).prop("disabled", false);
    $(`#mark-all-for-report-${circulation_id}`).prop("disabled", false).click();
    $("#create-report").prop("disabled", false);
  } else {
    $(`[data-show-circulation="${circulation_id}"]`).removeClass("report-mark");
    $(`#mark-all-for-report-${circulation_id}`).click().prop("disabled", true);
    $(`[data-mark-checkpoint="${circulation_id}"]`).prop("disabled", true);
  }
});

$body.on("click", "[data-mark-all]", function (e) {
  const is_checked = $(e.target).is(":checked");
  const id = $(e.target).data("mark-all");
  const todo = $(`[data-mark-checkpoint="${id}"]`);
  todo.prop("checked", is_checked);
  if (is_checked) {
    todo.closest(".checkpoint-header").addClass("report-mark");
  } else {
    todo.closest(".checkpoint-header").removeClass("report-mark");
  }
});

$body.on("click", "[data-mark-checkpoint]", function (e) {
  const checkbox = $(e.target);
  const is_checked = checkbox.is(":checked");
  if (is_checked) {
    checkbox.closest(".checkpoint-header").addClass("report-mark");
  } else {
    checkbox.closest(".checkpoint-header").removeClass("report-mark");
  }
});

$body.on("click", "#create-report", function () {
  const json = get_all_marked();
  const url = $("#download-report").data("url");
  $("#create-report").addClass("btn-loader");
  $.ajax({
    url: url,
    method: "POST",
    data: {
      ids: JSON.stringify(json),
      csrfmiddlewaretoken: getCookie("csrftoken"),
    },
    xhrFields: {
      responseType: "blob",
    },
    success: function (response) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      const date = new Date().toISOString().slice(0, 10).replaceAll("-", "");

      link.download = `${$("#selected-aca-project").html()}_${date}.docx`;
      document.body.appendChild(link);
      link.click();
      $("#create-report").removeClass("btn-loader");
    },
    error: function (response) {
      console.error("download of the report failed.");
      $("#create-report").removeClass("btn-loader");
    },
  });
});

function init_dt_star () {
  const active_icons = $(".icon-active-project");
  if (active_icons.length === 0) {
    setTimeout(init_dt_star, 500);
  } else {
    setTimeout(set_star, 500);
  }

  function set_star () {
    const project_name = $("#selected-aca-project").html();
    const designation = $(`.designation:contains(${project_name})`);
    $(".icon-inactive-project").removeClass("d-none");
    $(".icon-active-project").addClass("d-none");
    const active_pj = designation.closest("tr").find(".icon-active-project");
    if (active_pj.length > 0) {
      designation.closest("tr").find(".icon-active-project").removeClass("d-none");
    }
  }
}

if ($("#projects_table_wrapper").length > 0) {
  $body.on("click", "[data-pagination=\"projects_table\"]", init_dt_star);
  init_dt_star();
}

if ($("#top-btn").length > 0) {
  $body.on("click", "#top-btn", function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });

  window.onscroll = function () {
    const topBtn = document.getElementById("top-btn");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      topBtn.style.display = "block";
    } else {
      topBtn.style.display = "none";
    }
  };
}
